import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import Layout from "../components/Layout";
import Menu from "../components/Menu";

const AboutTemplate = ({ pageContext, location }) => {
  const [atLarge, setAtLarge] = useState(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    function handleTabletChange(e) {
      if (e.matches) {
        setAtLarge(true);
      } else {
        setAtLarge(false);
      }
    }
    mediaQuery.addListener(handleTabletChange);
    handleTabletChange(mediaQuery);
    return () => {
      mediaQuery.removeListener(handleTabletChange);
    };
  }, []);

  const { data: templateData } = pageContext;

  return (
    <Layout
      seoTitle={templateData.meta_title || templateData.title || "About"}
      seoDescription={templateData.meta_description}
      location={location}
    >
      <div className="pt-hh px-ogs pb-ogs">
        <div className="flex flex-wrap -ml-gs -mt-8">
          <div className="w-full lg:w-5/12 pl-gs pt-8">
            <div className="lg:sticky lg:top-hh lg:h-panel">
              {!atLarge && <Menu location={location} />}
              {templateData.image.fluid && (
                <>
                  <Image
                    className="hidden lg:block h-full w-full"
                    placeholderStyle={{ display: "none" }}
                    fluid={{
                      ...templateData.image.fluid,
                      aspectRatio: 0
                    }}
                    loading="eager"
                  />
                  <Image
                    className="block lg:hidden w-full"
                    placeholderStyle={{ display: "none" }}
                    fluid={templateData.image.fluid}
                  />
                </>
              )}
            </div>
          </div>
          <div className="w-full lg:w-7/12 pl-gs pt-8">
            <div className="h-full flex flex-col justify-between">
              {atLarge && <Menu location={location} />}
              {templateData.body.html && (
                <div
                  className="text-base md:text-lg lg:text-xl leading-relaxed space-y-4 lg:space-y-6"
                  dangerouslySetInnerHTML={{
                    __html: templateData.body.html
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

AboutTemplate.propTypes = {
  location: PropTypes.object.isRequired
};

export default AboutTemplate;
